

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-carousel .slick-slide img{
  object-fit: cover;
  width: 100%;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.home-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.home-slider-img{
  border-radius: 15px !important;
}


::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.myclassss{
  background: red;
}


.ant-card-body-homelogs>.ant-card-body::-webkit-scrollbar-thumb {
  background: #ff3f34; 
  border-radius: 10px;
}



.ordercol{
  padding-left: 15px;
  padding-right: 15px;
}


/*çek sürükle sırala */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-maker-dot{

  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: grow;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  
  transition-property: -moz-transform;
  transition-duration: 1s;
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  
}

@keyframes grow {
  from {
      transform: scale(0.3);
  }
  to {
      transform: scale(1.0533) 0.5s;
  }
}




.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 0 10px;
  height: 10px;
  width: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

.blob.redstatic {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

.blob.greenstatic {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}


.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}
.blob.orangestatic {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}



.ant-carousel .slick-slider {
  text-align: center;
  height: 460px;
  line-height: 160px;
  background: #f8f8f8;
  overflow: hidden;
  object-fit: cover;
}
.ant-carousel {
  padding-bottom: 16px;
}
.ant-carousel .slick-slider h3 {
  color: #fff;
}
.float-right {
  float: right;
}

.ant-card-cover img{
  height: 200px;
  object-fit: cover;
}





#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

.my_strip_info{
  position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: block;

}
.my_strip_info_img{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    /* z-index: 1; */
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.my_strip_info_top{
  margin-bottom: 5px;
  overflow: hidden;
  position: relative;
  height: 190px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #ededed;
}

.my-class-pr-item{
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    padding: 0 5px;
}

.my-class-pr-img{
  object-fit: cover;
  width: 120px;
  height: 120px;
}
.my-class-pr-img-tab{
  object-fit: cover;
  width: 64px;
  height: 64px;
}

.my-class-pr-parent{
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  background-color: #f53b57;
}


.site-custom-tab-bar {
  z-index: 1;
  background: #fff;
}
.mbottom5{
  margin-bottom: 5px;
}

.bottom-bar{
  position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0px 20px;
    border-top: 0.8px solid rgba(19, 51, 55, 0.3);
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
}


.border-0{
  border-radius: 0;
}








#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 5px;
}
#invoice-POS #invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 2mm;
  margin: 0 auto;
  width: 44mm;
  background: #FFF;
}
#invoice-POS #invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS #invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS #invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}
#invoice-POS #invoice-POS h2 {
  font-size: 0.9em;
}
#invoice-POS #invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS #invoice-POS p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}
#invoice-POS #invoice-POS #top, #invoice-POS #invoice-POS #mid, #invoice-POS #invoice-POS #bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
}
#invoice-POS #invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #invoice-POS #mid {
  min-height: 80px;
}
#invoice-POS #invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #invoice-POS #top .logo {
  height: 60px;
  width: 60px;

  background-size: 60px 60px;
}
#invoice-POS #invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;

  background-size: 60px 60px;
  border-radius: 50px;
}
#invoice-POS #invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS #invoice-POS .title {
  float: right;
}
#invoice-POS #invoice-POS .title p {
  text-align: right;
}
#invoice-POS #invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS #invoice-POS .tabletitle {
  font-size: 0.5em;
  background: #EEE;
}
#invoice-POS #invoice-POS .service {
  border-bottom: 1px solid #EEE;
}
#invoice-POS #invoice-POS .item {
  width: 24mm;
}
#invoice-POS #invoice-POS .itemtext {
  font-size: 0.5em;
}
#invoice-POS #invoice-POS #legalcopy {
  margin-top: 5mm;
}
#invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}
#invoice-POS h2 {
  font-size: 0.9em;
}
#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}
#invoice-POS #top, #invoice-POS #mid, #invoice-POS #bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
}
#invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #mid {
  min-height: 80px;
}
#invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #top .logo {
  height: 60px;
  width: 60px;
  background-size: 60px 60px;
}
#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
#invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS .title {
  float: right;
}
#invoice-POS .title p {
  text-align: right;
}
#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS .tabletitle {
  font-size: 0.5em;
  background: #EEE;
}
#invoice-POS .service {
  border-bottom: 1px solid #EEE;
}
#invoice-POS .item {
  width: 24mm;
}
#invoice-POS .itemtext {
  font-size: 0.5em;
}
#invoice-POS #legalcopy {
  margin-top: 5mm;
}

.invoice-POS-footer{
  margin-top: 10px;
}

