

.live {
  padding-top: 10px;
}

.completed {
  padding-top: 5px;
}

.empty {
  font-size: 14px;
  color: #999;
}

.time-elapsed {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.time-elapsed strong {
  color: white;
  display: block;
  padding: 20px 40px;
  border-radius: 40px;
  transition: 0.5s all;
  font-weight: 100;
  font-size: 26px;
  background: #fea443;
}

.time-elapsed .active {
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  0%   { background-color: #84d3a8; }
  100% { background-color: #35b06c; }
}